<div class="row">
    <div class="col home-container">
        <div class="row flex center">
            <h2>sharing links has never been easier</h2>
        </div>
        <div class="row home">
            <div class="col-md-6 flex-center">
                <app-create-url-form></app-create-url-form>
            </div>
            <div class="col-md-6 flex-center">
                <div id="go-video">
                    <h6>introducing go.chevron.com</h6>
                    <iframe title="introduction" width="550" height="320" src="https://web.microsoftstream.com/embed/video/ecd4c400-7334-4e4a-804e-3b21e69ba624?autoplay=false&showinfo=false" 
                    allowfullscreen style="border:none;"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
