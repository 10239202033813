import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { CalService } from '../cal/cal.service';
import { Observable, from } from 'rxjs';


export class GraphUserProps {
    public "id": string;
    public "displayName": string;
    public "mail": string
}
export class GraphUserResponse {
    public "value": GraphUserProps[]
}

@Injectable({
    providedIn: 'root',
})
export class UserHttpClient extends HttpClient {
    private graphBaseUrl: string = "https://graph.microsoft.com"
    private httpHeaders: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });

    public static UserSearchKey: string = "graph-search";

    constructor(
        handler: HttpHandler,
        private cal: CalService) {
        super(handler);
    }

    private encodeGetParams(p: Object): string {
        return Object.entries(p).map(kv => kv.join("=")).join("&")
    }

    public getUser(objectid: string): Observable<GraphUserProps> {
        const params = {
            "$select": `id,displayName,mail`
        };


        const fullApiEndPointUrl = `${this.graphBaseUrl}/v1.0/users/${objectid}?${this.encodeGetParams(params)}`;

        return from(this.updateAuthorizationHeader(this.httpHeaders)
            .then(_headers => this.get<GraphUserProps>(encodeURI(fullApiEndPointUrl), {
                headers: _headers
            }).toPromise()));
    }

    public searchForUser(searchTerm: string, maxNumResults: number = 15): Observable<GraphUserResponse> {
        maxNumResults = Math.trunc(maxNumResults);
        if (maxNumResults < 0) {
            maxNumResults = 15;
        }
        else if (maxNumResults > 100) {
            maxNumResults = 100;
        }

        const params = {
            "$filter": `accountEnabled eq true and startswith(displayName,'${searchTerm.replace("'", "''")}') and (extension_39c7d3e68666465dab296ee0fc538118_cvx_PersonRelationshipType eq 'Employee' or extension_39c7d3e68666465dab296ee0fc538118_cvx_PersonRelationshipType eq 'Contractor' or extension_39c7d3e68666465dab296ee0fc538118_cvx_PersonRelationshipType eq 'JV Partner')`,
            "$top": maxNumResults,
            "$select": `id,displayName,mail`
        };


        const fullApiEndPointUrl = `${this.graphBaseUrl}/v1.0/users?${this.encodeGetParams(params)}`;

        return from(this.updateAuthorizationHeader(this.httpHeaders)
            .then(_headers => this.get<GraphUserResponse>(encodeURI(fullApiEndPointUrl), {
                headers: _headers
            }).toPromise()))
    }

    private updateAuthorizationHeader(headers: HttpHeaders): Promise<HttpHeaders> {
        const scopes = [`${this.graphBaseUrl}/user.read.all`];

        return this.cal.getAADToken(scopes, false).then((_token: string) => {
            return headers.append("Authorization", `Bearer ${_token}`);
        })
    }
}