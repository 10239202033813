import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { NextPage } from '@cvx/nextpage';
import { CalAngularModule } from './shared/cal/cal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateUrlFormComponent } from './shared/forms/create-url-form/create-url-form.component';
import { UpdateUrlFormComponent } from './shared/forms/update-url-form/update-url-form.component';
import { DetailsComponent } from './pages/details/details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchComponent } from './pages/search/search.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OpenMsTeamsDirective } from './shared/directives/open-ms-teams.directive';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { LocalDatePipe } from './shared/pipes/local-date.pipe';
import { DatePipe } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { BrandedLayoutComponent } from './shared/components/branded-layout/branded-layout.component';
import { ReferrersComponent } from './pages/referrers/referrers.component';
import { DuplicateLinksComponent } from './shared/components/duplicate-links/duplicate-links.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UrlOwnersComponent } from './shared/components/url-owners/url-owners.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ErrorHandlerService } from './shared/services/error-handler-service';
import { AppInsightsService } from './shared/services/app-insights.service';
import { RedirectActivitiesComponent } from './pages/redirect-activities/redirect-activities.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AddOwnersFormComponent } from './shared/forms/add-owners-form/add-owners-form.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreateUrlFormComponent,
    UpdateUrlFormComponent,
    AddOwnersFormComponent,
    DetailsComponent,
    SearchComponent,
    OpenMsTeamsDirective,
    TruncatePipe,
    LocalDatePipe,
    RedirectComponent,
    BrandedLayoutComponent,
    ReferrersComponent,
    DuplicateLinksComponent,
    ConfirmDialogComponent,
    UrlOwnersComponent,
    RedirectActivitiesComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTooltipModule,
    FontAwesomeModule,
    NextPage,
    CalAngularModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    OverlayModule
  ],
  providers: [
    DatePipe,
    AppInsightsService,
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  entryComponents: [ConfirmDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
